<template>
  <div class="mapContent">
    <!-- <div id="divwxMap" class="divwxMap" style="width: width+'px'; height: height+'px'"></div> -->
    <div id="divwxMap" class="divwxMap" style="width: 100%; height:100%"></div>

    <cun-mode ref="cunMode"></cun-mode>
  </div>
</template>

<script>
import CunMode from './cunMode.vue'
import mapJson from '../utils/map.json'
export default {
  name: 'testJwds.vue',
  components: { CunMode },
  props: { form: {} },
  data() {
    return {
      width: '',
      height: '',
      // 控制点位的
      pinkChecked: true, //
      purpleChecked: true,

      wxMap: null,
      mapType: null,
      zoom: 13,
      // 地标点数据
      pointData: [
        { longitude: 117.404137, latitude: 36.917403, name: 'AAA' },
        { longitude: 117.094396, latitude: 36.510754, name: 'BBB' },
        { longitude: 117.092396, latitude: 36.310754, name: 'CCC' },
        { longitude: 116.392396, latitude: 36.710754, name: 'DDD' },
        { longitude: 116.992396, latitude: 37.110754, name: 'ZZZ' }
      ],
      iconMaker: [] //用来装标注点。
    }
  },
  watch: {
    pinkChecked(nval, oval) {
      if (nval == true) {
        this.point() // 粉色
      } else {
        this.clearIcon('pink')
      }
    }
  },
  beforeMount() {
    this.width = document.body.clientWidth
    this.height = document.body.clientHeight
    console.log(this.width)
    console.log(this.height)
  },
  mounted() {
    this.initwxMap()
    this.point() // 粉色
    this.region() // 区域
  },
  methods: {
    // 初始化天地图
    initwxMap() {
      // vue项目需要先声明 T = window.T，不然后面无法获取到。
      let T = window.T
      this.wxMap = null
      let initMap = new T.Map('divwxMap') // div的id
      // 传参中心点经纬度，以及放大程度，最小1，最大18
      initMap.centerAndZoom(new T.LngLat(119.97503, 41.406052), 12)
      initMap.setStyle('black')
      initMap.enableDrag()
      initMap.enableScrollWheelZoom()
      this.wxMap = initMap
    },
    // 添加地标点
    point(e) {
      var T = window.T
      // this.pointData = [
      //   { longitude: 117.404137, latitude: 36.917403, name: 'AAA' },
      //   { longitude: 117.094396, latitude: 36.510754, name: 'BBB' },
      //   { longitude: 117.092396, latitude: 36.310754, name: 'CCC' },
      //   { longitude: 116.392396, latitude: 36.710754, name: 'DDD' },
      //   { longitude: 116.992396, latitude: 37.110754, name: 'ZZZ' },
      // ]
      this.pointData = [
        {
          id: 10,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '卢杖子村',
          icon: null,
          longitude: '119.959664',
          latitude: '41.447236',
          createBy: '10',
          createTime: null,
          updateBy: '10',
          updateTime: null,
          introduction: ''
        },
        {
          id: 11,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '康家屯村',
          icon: null,
          longitude: '119.995884',
          latitude: '41.430127',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction: ''
        },
        {
          id: 12,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '焦营子村',
          icon: null,
          longitude: '119.964123',
          latitude: '41.433363',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction: ''
        },
        {
          id: 13,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '南洼村',
          icon: null,
          longitude: '119.948346',
          latitude: '41.409548',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction: ''
        },
        {
          id: 14,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '白营子村',
          icon: null,
          longitude: '119.999482',
          latitude: '41.41467',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction: ''
        },
        {
          id: 15,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '波罗赤村',
          icon: null,
          longitude: '119.974596',
          latitude: '41.409415',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction: ''
        },
        {
          id: 16,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '华家店村',
          icon: null,
          longitude: '119.933533',
          latitude: '41.390217',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction: ''
        },
        {
          id: 17,
          pid: 6,
          cId: null,
          cParentId: 6283,
          regionLevel: '5',
          regionCode: '101071201',
          regionName: '肖三家村',
          icon: null,
          longitude: '119.993225',
          latitude: '41.379003',
          createBy: '11',
          createTime: null,
          updateBy: '11',
          updateTime: null,
          introduction:
            '<p>\t肖三家村位于波罗赤镇镇南部。有十个村民组，705户2315人，有党员57名。全村耕地面积10800亩，其中水浇地4000亩，林地面积6000亩，人均耕地4.8亩。农业产业以玉米种植为主，养殖牛、羊、猪、鸡的农户数量不多，受市场、技术、资金制约，增收能力十分有限。外出务工仍然是全村农民增加收入的主要途径。</p>'
        }
      ]
      // 点位数据
      this.pointData.forEach(item => {
        // 创建标注对象
        const marker = new T.Marker(new T.LngLat(item.longitude, item.latitude), {
          icon: new T.Icon({
            iconUrl: require('@/assets/map/icon.png'), // 引入自定义图标
            iconSize: new T.Point(26, 26) // 图标大小
          })
        })
        marker.id = 'pink' // 给图标加个名字  方便删除
        // console.log(marker, 'marker');
        // 向地图上添加标注
        this.wxMap.addOverLay(marker)
        // 点位的点击事件，展示弹窗
        this.addClickHandler(item, marker)
      })
    },
    // 点位弹窗
    addClickHandler(content, marker) {
      let T = window.T
      const that = this
      marker.addEventListener('click', function(e) {
        console.log(content)
        that.$refs.cunMode.initData(content)
      })
    },
    // 清除图标
    clearIcon(e) {
      // console.log('显示地标', e.currentTarget.innerText); // 当前点击的图标名称
      // this.wxMap.clearOverLays();
      let that = this
      var newMarker = that.wxMap.getOverlays() // 获取到了地图上的所有点
      console.log(newMarker, 'newMarker')
      for (var n = 0; n < newMarker.length; n++) {
        for (var i = 0; i < that.pointData.length; i++) {
          if (e == newMarker[n].id) {
            that.wxMap.removeOverLay(newMarker[n])
          }
        }
      }
    },

    // 区域图
    region() {
      let that = this
      var countriesOverlay = new T.D3Overlay(this.init, this.redraw)
      var countriesOverlay1 = new T.D3Overlay(this.init1, this.redraw1)
      var map = this.wxMap
      // 获取省市区域数据
      //https://geo.datav.aliyun.com/areas_v3/bound/210000_full.json
      console.log(mapJson)
      // d3.json('../utils/map.geojson', function (data) {
      //   console.log(data)
      let aa = that.form.addres || '辽宁省朝阳市朝阳县波罗赤镇'
      that.countries = mapJson.features
      map.addOverLay(countriesOverlay)
      countriesOverlay.bringToBack()
      map.addOverLay(countriesOverlay1)
      countriesOverlay.bringToBack()
      // })
    },
    // 面
    init(sel, transform) {
      var upd = sel.selectAll('path.geojson').data(this.countries)
      upd
        .enter()
        .append('path')
        .attr('class', 'geojson')
        .attr('fill', function(d, i) {
          console.log('4444')
          return d3.hsl(Math.random() * 3000, 0.9, 0.5) // 区域图色彩范围
        })
        .attr('fill-opacity', '0.3') // 区域图透明度
    },
    redraw(sel, transform) {
      sel.selectAll('path.geojson').each(function(d, i) {
        console.log('555')
        d3.select(this).attr('d', transform.pathFromGeojson)
      })
    },

    // 线
    init1(sel, transform) {
      var upd = sel.selectAll('path.geojson1').data(this.countries)
      upd
        .enter()
        .append('path')
        .attr('class', 'geojson1')
        .attr('stroke', '#ff9') // 边界线颜色
        .attr('fill-opacity', '0')
    },
    redraw1(sel, transform) {
      sel.selectAll('path.geojson1').each(function(d, i) {
        d3.select(this).attr('d', transform.pathFromGeojson)
      })
    }
  }
}
</script>

<style scoped lang="scss">
// 去掉天地图的logo
/deep/ .tdt-control-container {
  display: none !important;
}
// 去掉三角
/deep/ .tdt-infowindow-tip-container {
  display: none;
}
/deep/ .tdt-zoom-animated {
  bottom: 15px !important;
}
/deep/ .tdt-infowindow-content {
  min-width: 170px !important;
  min-height: 100px !important;
  width: auto !important;
  height: auto !important;
}
/deep/ .tdt-infowindow-content-wrapper {
  // background: url('../assets/bg.png') no-repeat;
  background-size: 100% 100%;
  box-shadow: none;
}
#mapDiv {
  height: 400px;
  width: 100%;
}
.skin {
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 120px;
  background-color: #afebd6;
  margin-right: 20px;
  button {
    height: 40px;
    line-height: 40px;
    &:hover {
      background-color: #8de2c4;
    }
  }
}
.point {
  display: flex;
  flex-direction: column;
  width: 120px;
  background-color: rgba(135, 207, 235, 0.774);
  margin-right: 20px;
  height: 120px;
  > .el-checkbox {
    line-height: 50px;
    height: 50px;
    margin-right: 0;
    &:hover {
      background-color: rgba(135, 207, 235, 1);
      cursor: pointer;
    }
  }
}

.zoomBox {
  position: absolute;
  left: 10px;
  top: 90px;
  display: flex;
  flex-direction: column;
  // width: 50px;
  height: 100px;
  z-index: 999;
  button {
    width: 34px;
    height: 34px;
    background-color: #fff;
    font-size: 22px;
    border: 1px solid #999;
    &:hover {
      background-color: rgb(233, 233, 233);
    }
  }
}
.mapContent {
  width: 100%;
  height: 100%;
  z-index: 0;
  .divwxMap {
    z-index: 0 !important;
    position: fixed !important;
    top: 0;
    left: 0;
  }
}
</style>
