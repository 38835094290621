<template>
  <div class="main">
    <dome></dome>
  </div>
</template>

<script>
import dome from '../components/map2.vue';
export default {
  components: {
    dome,
  },
}
</script>

<style>
.main{
  width: 100%;
  height: 100%;
}
</style>
